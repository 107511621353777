<template>
  <div>
    <el-tabs v-model="form.status"
             @tab-click="search">
      <el-tab-pane label="未审核"
                   name="一">
      </el-tab-pane>
      <el-tab-pane label="已通过"
                   name="二"></el-tab-pane>
      <el-tab-pane label="已更改"
                   name="三"></el-tab-pane>
      <el-tab-pane label="未通过"
                   name="四"></el-tab-pane>
    </el-tabs>
    <el-form :model="form"
             label-width="80px"
             ref="formRef">
      <el-row :gutter="10">
        <el-form-item label="科目：">
          <span v-for="item in subjectList"
                :key="item.subject_id"
                :class="{'is_active':item.subject_id == form.subject_id,'subject':true}"
                @click="setSubject(item.subject_id)">{{item.subject_name}}</span>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="类型：">
          <span v-for="item in typeList"
                :key="item.value"
                :class="{'is_active':item.value === form.category,'subject':true}"
                @click="setTypeValue(item.value)">{{item.label}}</span>
        </el-form-item>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="反馈人:">
            <el-input placeholder="请输入ID/姓名"
                      clearable
                      v-model="form.student"
                      style="width:100%" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="录入人:">
            <el-input placeholder="请输入ID/姓名"
                      clearable
                      v-model="form.input_user"
                      style="width:100%" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="反馈时间:">
            <el-date-picker v-model="dates"
                            style="width:100%"
                            @focus="$forbid"
                            type="datetimerange"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            range-separator="至"
                            start-placeholder="开始日期"
                            @change="search"
                            :default-time="['00:00:00', '23:59:59']"
                            end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-button type="primary"
                     @click="search">查询</el-button>
        </el-col>
      </el-row>
    </el-form>

    <Table ref="TablesRef"
           :tableData="tableData" />

    <InfoForm ref="InfoFormRef">
    </InfoForm>
    <notPass ref="notPassRef" />
    <pass ref="passRef" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
import InfoForm from './components/info.vue'
import notPass from './components/notPass.vue'
import pass from './components/pass.vue'
export default {
  data () {
    return {
      typeList: [
        { label: '全部', value: '' },
        { label: '题目反馈', value: '0' },
        { label: '批改反馈', value: '1' },
      ],
      dates: [],
      form: {
        audit: 1,
        status: '一',
        subject_id: '',
        start_time: '',
        end_time: '',
        category: '',
        student: '',
        input_user: '',
      },
      comment: '',
      subjectList: [],
      isPG: false,
      page: 1,
      size: 10,
      totalElements: 0,
      tableData: [],
      pageName: 'cz_feedback_audit_admin',
      tableColumns: [
        { prop: 'question_error_id', align: 'center', label: '编号', width: '' },
        { prop: 'user_id', align: 'center', label: '反馈人ID', width: '' },
        { prop: 'user_name', align: 'center', label: '姓名', width: '100' },
        { prop: 'subject_name', align: 'center', label: '科目', width: '' },
        { prop: 'question_id', align: 'center', label: '题目ID', width: '100px' },
        { prop: 'category', align: 'center', label: '反馈类型', width: '', isStatus: true },
        { prop: 'comment', align: 'center', label: '反馈内容', width: '' },
        { prop: 'question_error_time', align: 'center', label: '反馈时间', width: '' },
        { prop: 'is_teacher', align: 'center', label: '是否教师', width: '', isStatus: true },
        { prop: 'input_user_id', align: 'center', label: '录入人/批改人ID', width: '' },
        { prop: 'edu_user_name', align: 'center', label: '录入人/批改人姓名', width: '100px' },
        {
          prop: 'd', align: 'center', label: '操作', width: '100', type: 'img', btns: [
            { content: '查看', type: 'look', event: 'checkInfo' },
          ]
        },
      ],
      info: {}
    }
  },
  components: {
    InfoForm, notPass, pass
  },
  mounted () {
    this.getSubject()
    this.setTablesColums()
  },
  methods: {
    getClass (prop, value) {
      if (prop == 'is_teacher') {
        return value ? 'active' : 'noactive'
      }
      return ''
    },
    getValue (prop, value, row) {
      if (prop == 'category') {
        return value ? '批改错误' : '题目报错'
      }
      if (prop == 'is_teacher') {
        return value ? '是' : '否'
      }
      return value
    },
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    setSubject (value) {
      this.form.subject_id = value
      this.search()
    },
    initData (page, limit) {
      this.form.page = page
      this.form.limit = limit
      let form = JSON.parse(JSON.stringify(this.form))
      if (this.dates.length) {
        form.begin_time = this.dates[0]
        form.end_time = this.dates[1]
      }
      switch (form.status) {
        case '一':
          form.status = 0
          break;
        case '二':
          form.status = 1
          break;
        case '三':
          form.status = 2
          break;
        case '四':
          form.status = -1
      }
      this.$http({
        url: '/api/v1/error/lst',
        method: 'get',
        params: form
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },
    delRow (row) {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v1/file/del',
          method: 'post',
          data: {
            id: row.id
          }
        }).then(() => {
          this.$notify({
            title: '提示',
            message: '删除成功!',
            type: 'success'
          });
          this.search()
        })
      })
    },
    // 查询
    search () {
      this.$refs.pagination.toFirstPage()
    },
    getSubject () {
      this.$http({
        url: '/api/v1/papers/subject',
        method: 'get'
      }).then(res => {
        this.subjectList = res.data.subject
      })
    },
    checkInfo (item) {
      if (this.form.status != '一') {
        this.$refs.InfoFormRef.showOpertion = false
      }
      this.$refs.InfoFormRef.getInfo(item)
      // this.$http({
      //   url: '/api/v1/error/question_approval',
      //   method: 'get',
      //   params: {
      //     question_error_id: item.question_error_id
      //   }
      // }).then(res => {
      //   res.data.question_error_id = item.question_error_id
      //   res.data.user_id = item.user_id
      //   res.data.category = item.category

      //   this.$refs.InfoFormRef.form = res.data
      //   this.$refs.InfoFormRef.dialogVisible = true
      // })
    },
    setTypeValue (value) {
      this.form.category = value
      this.search()
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-tabs__item {
  font-size: 19px;
  width: 150px;
  text-align: center;
  padding-right: 10px;
  padding: 0;
}
.is_active {
  color: #409eff;
}
.subject {
  display: inline-block;
  padding: 0 10px;
  cursor: pointer;
  &:hover {
    color: #409eff;
  }
}
.status {
  padding: 0px 10px;
  display: inline-block;
  border-radius: 5px;
}
.weishanhe {
  color: #3f9eff;
  background: #eff8ff;
  padding: 0px 10px;
  display: inline-block;
  border-radius: 5px;
}

.pass {
  color: #41d7d7;
  background: #effffe;
  padding: 0px 10px;
  display: inline-block;
  border-radius: 5px;
}

.notpass {
  color: #fe765f;
  background: #ffefef;
  padding: 0px 10px;
  display: inline-block;
  border-radius: 5px;
}

.line {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  cursor: pointer;
  &:hover {
    color: #409eff;
  }
}
.table_line {
  width: 100%;
  height: 40px;
  line-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}
.btn_warp {
  display: flex;
  justify-content: center;
  .btn {
    padding: 5px 23px;
    color: #ffffff;
    font-weight: bold;
    font-size: 16px;
    border-radius: 15px;
    cursor: pointer;
  }
  .primary {
    background: #3f9eff;
  }
  .danger {
    background: #fe765f;
    margin-left: 15px;
  }
}
.comment {
  font-size: 16px;
  font-weight: bold;
  color: black;
}
</style>